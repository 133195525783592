.routes-header {
  background: linear-gradient(to top, #ffffff, #ebf1f5);
  margin-top: 80px;
  min-height: calc(97vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}
